import React, { useState, useEffect } from "react";
import axios from "axios";
import _, { map } from "lodash";
import Boards from "../components/Boards";
import { useLocation } from "@reach/router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/Layout";

import {
  Button,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  loading: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: "auto",
    marginLeft: "auto",
  },
});

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

function Rapport() {
  let baseUrl = "https://graph.facebook.com/v18.0/";

  const location = useLocation();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [idEntreprise, setIdEntreprise] = useState("");
  const [facebookAccountId, setFacebookAccountId] = useState("");
  const [level, setLevel] = useState("Campaigns");
  const [taux, setTaux] = useState(0);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [selectedReport, setSelectedReport] = useState("Diffusion");
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [marques, setMarques] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);

  const [ads, setAds] = useState([]);
  const [campaignsData, setCampaignsData] = useState([]);
  const [adsetsData, setAdsetsData] = useState([]);
  const [uniqueCampaignsData, setUniqueCampaignsData] = useState([]);
  const [uniqueAds, setUniqueAds] = useState([]);
  const [allCreatives, setAllCreatives] = useState(null);

  let campaignes = JSON.stringify(
    selectedCampaigns.map((element) => {
      return element.campaign_id;
    })
  );

  useEffect(() => {
    let query = parseQuery(location.search);

    let tx = null;
    if (query.id) {
      airtable(0);
      setSelectedClient(0);
    }
  }, []);

  useEffect(() => {
    airtable(selectedClient);
  }, [selectedClient]);

  async function airtable(selectedClient) {
    let query = parseQuery(location.search);
    let facebookAccountId = "";
    try {
      let data = await axios
        .get(`/api/airtable-prix?pour_le_compte_de=${query.id}`)
        .then((res) => {
          setMarques(res.data);
          // console.log(res.data);
          setLoading(false);
          return res.data[selectedClient];
        });

      setTaux(data.Multiple_FB_IG);
      setFacebookAccountId(data.facebookAccountId);
      setIdEntreprise(data.Pour_le_compte_de);

      let timeDifference =
        (Date.now() -
          localStorage.getItem(
            `allCampaign_${data.Pour_le_compte_de[0]}_time`
          )) /
        1000 /
        3600;
      let nombreHeureDactualisation = 1;
      if (
        localStorage.getItem(`allCampaign_${data.Pour_le_compte_de}`) &&
        !_.isEmpty(
          JSON.parse(
            localStorage.getItem(`allCampaign_${data.Pour_le_compte_de}`)
          )
        ) &&
        JSON.parse(
          localStorage.getItem(`allCampaign_${data.Pour_le_compte_de}`)
        ).length !== 0 &&
        timeDifference < nombreHeureDactualisation
      ) {
        setAllCampaigns(
          JSON.parse(
            localStorage.getItem(`allCampaign_${data.Pour_le_compte_de}`)
          )
        );
      } else {
        let allCampaigns = await facebookApi(
          "allCampaigns",
          data.facebookAccountId,
          `/insights?fields=campaign_name,campaign_id&limit=100&date_preset=maximum&level=campaign&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${data.Pour_le_compte_de}"}]`
        ).then((res) => {
          for (let index = 0; index < res.length; index++) {
            if (res[index].campaign_name.includes("|")) {
              res[index].campaign_name = res[index].campaign_name.split("|")[0];
            }
          }
          return res;
        });
        localStorage.setItem(
          `allCampaign_${data.Pour_le_compte_de}`,
          JSON.stringify(allCampaigns)
        );
        setAllCampaigns(allCampaigns);
        localStorage.setItem(
          `allCampaign_${data.Pour_le_compte_de}_time`,
          Date.now()
        );

        if (
          !localStorage.getItem(`allCampaign_${data.Pour_le_compte_de}_time`)
        ) {
          localStorage.setItem(
            `allCampaign_${data.Pour_le_compte_de}_time`,
            Date.now()
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (facebookAccountId) {
      // facebookApi("adcreatives",facebookAccountId,`/adcreatives?fields=account_id,body,object_story_spec,object_type,object_url,thumbnail_url,link_url,instagram_permalink_url,source_instagram_media_id,object_story_id,effective_object_story_id,object_id,status&thumbnail_width=250&thumbnail_height=250&limit=100`)

      crea();
    }

    async function crea() {
      setLoading(true);
      let local = localStorage.getItem(facebookAccountId);
      let timeDiffence =
        (Date.now() - localStorage.getItem(`${facebookAccountId}_time`)) /
        1000 /
        3600;
      let nombreHeureDactualisation = 1;
      if (
        localStorage.getItem(facebookAccountId) &&
        !_.isEmpty(JSON.parse(localStorage.getItem(facebookAccountId))) &&
        JSON.parse(localStorage.getItem(facebookAccountId)).length !== 0 &&
        timeDiffence < nombreHeureDactualisation
      ) {
        setAllCreatives(JSON.parse(localStorage.getItem(facebookAccountId)));
        setLoading(false);
      } else {
        let allCrea = await facebookApi(
          "adcreatives",
          facebookAccountId,
          `/adcreatives?fields=account_id,body,object_story_spec,object_type,object_url,thumbnail_url,link_url,instagram_permalink_url,source_instagram_media_id,object_story_id,effective_object_story_id,object_id,status&thumbnail_width=250&thumbnail_height=250&limit=100`
        ).then((res) => {
          return res;
        });
        setAllCreatives(allCrea);
        localStorage.setItem(facebookAccountId, JSON.stringify(allCrea));
        localStorage.setItem(`${facebookAccountId}_time`, Date.now());
        setLoading(false);

        if (!localStorage.getItem(`${facebookAccountId}_time`)) {
          localStorage.setItem(`${facebookAccountId}_time`, Date.now());
        }
      }
    }
  }, [facebookAccountId]);

  const handleChange = (event, newValue) => {
    setLevel(newValue);
  };

  //  let test =  allCreatives  && allCreatives.map((el)=>{
  //     console.log(el.thumbnail_url)
  //   })

  let creativeIdFunc = async (facebookAccountId) => {
    try {
      let tableau = [];
      let uniqueAdsData = [];

      let res = await facebookApi(
        "ads",
        facebookAccountId,
        `/insights?fields=ad_name,ad_id,adset_name,adset_id,created_time,impressions,campaign_name,campaign_id,reach,spend,objective,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_continuous_2_sec_watched_actions,video_15_sec_watched_actions,video_30_sec_watched_actions,video_play_actions,video_avg_time_watched_actions,actions&action_breakdowns=["action_reaction"]&limit=100&date_preset=maximum&level=ad&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"campaign.id","operator":"IN", "value":${campaignes}}]`
      ).then((res) => {
        return res;
      });
      let allAds = await facebookApi(
        "allAds",
        facebookAccountId,
        `/ads?fields=creative&limit=100&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"campaign.id","operator":"IN", "value":${campaignes}}]`
      ).then((res) => {
        return res;
      });

      let adsWithCreative = res.map((item, index) => {
        let adsTab = allAds.filter((el) => el.id === item.ad_id);

        let copy = item;
        copy.creative_id = adsTab[0].creative.id;

        return copy;
      });

      let adsWithCreativeWithContent = adsWithCreative.map((item) => {
        let creativeData = allCreatives.filter(
          (el) => el.id === item.creative_id
        );

        item.responseCreative = creativeData[0];
        // console.log("adsWithCreative", item);
        return item;
      });

      setAds(adsWithCreativeWithContent);

      let getUnique = getUniqueAds(adsWithCreativeWithContent);

      for (let index = 0; index < getUnique.length; index++) {
        uniqueAdsData[index] = await facebookApi(
          "uniqueAds",
          facebookAccountId,
          `/insights?fields=ad_id,impressions,campaign_name,campaign_id,reach,spend,objective,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_continuous_2_sec_watched_actions,video_15_sec_watched_actions,video_30_sec_watched_actions,video_play_actions,video_avg_time_watched_actions,actions&action_breakdowns=["action_reaction"]&limit=100&date_preset=maximum&level=account&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"ad.id","operator":"IN", "value":${JSON.stringify(
            getUnique[index].ad_id
          )}}]`
        ).then((res) => {
          return res;
        });

        uniqueAdsData[index] = uniqueAdsData[index][0];
        uniqueAdsData[index].ad_id = getUnique[index].ad_id[0];
        uniqueAdsData[index].effective_object_story_id =
          getUnique[index].effective_object_story_id[0];
        uniqueAdsData[index].object_story_id =
          getUnique[index].object_story_id[0];
        uniqueAdsData[index].object_type = getUnique[index].object_type[0];
        uniqueAdsData[index].thumbnail_url = getUnique[index].thumbnail_url[0];
        uniqueAdsData[index].body = getUnique[index].body[0];
        uniqueAdsData[index].instagram_permalink_url =
          getUnique[index].instagram_permalink_url[0];
        uniqueAdsData[index].object_story_spec =
          getUnique[index].object_story_spec[0];
        uniqueAdsData[index].object_id = getUnique[index].object_id[0];
        uniqueAdsData[index].object_url = getUnique[index].object_url[0];
        uniqueAdsData[index].link_url = getUnique[index].link_url[0];
        uniqueAdsData[index].source_instagram_media_id =
          getUnique[index].source_instagram_media_id[0];
        uniqueAdsData[index].status = getUnique[index].status[0];
      }

      setUniqueAds(uniqueAdsData);
      setLoading(false);

      return adsWithCreativeWithContent;
    } catch (error) {
      console.log("creativeId : ", error);
    }
  };

  async function facebookApi(dataType, FacebookAccountID, parametre) {
    if (FacebookAccountID) {
      let chkoupi = await karim(FacebookAccountID, parametre);

      if (dataType === "campaignsData") {
        setCampaignsData(chkoupi);
      } else if (dataType === "adsetsData") {
        setAdsetsData(chkoupi);
      } else if (dataType === "uniqueCampaignsData") {
        setUniqueCampaignsData(chkoupi);
      } else if (dataType === "allCampaigns") {
        return chkoupi;
      } else if (dataType === "ads") {
        return chkoupi;
      } else if (dataType === "uniqueAds") {
        return chkoupi;
      } else if (dataType === "adcreatives") {
        return chkoupi;
      } else if (dataType === "allAds") {
        return chkoupi;
      }
    }
  }

  async function karim(FacebookAccountID, parametre) {
    let tableau = [];

    let params = parametre;
    let accountID = `act_${FacebookAccountID}`;
    let url = baseUrl + accountID + params;

    while (url) {
      try {
        let response = await axios({
          method: "post",
          url: "/api/insights",
          data: {
            url: url,
          },
        });

        let tab = response.data;
        // console.log(tab)

        if (tab.paging.next) {
          for (let i = 0; i < tab.data.length; i++) {
            tab.data[i].spend = tab.data[i].spend * taux;
            tableau = [...tableau, tab.data[i]];
          }
          url = tab.paging.next;
        } else {
          for (let i = 0; i < tab.data.length; i++) {
            tab.data[i].spend = tab.data[i].spend * taux;
            tableau = [...tableau, tab.data[i]];
          }
          url = undefined;
        }
      } catch (error) {
        console.log(error);
        url = undefined;
      }
    }

    return tableau;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    facebookApi(
      "campaignsData",
      facebookAccountId,
      `/insights?fields=impressions,campaign_name,campaign_id,reach,spend,objective,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_continuous_2_sec_watched_actions,video_15_sec_watched_actions,video_30_sec_watched_actions,video_play_actions,video_avg_time_watched_actions,actions&action_breakdowns=["action_reaction"]&limit=100&date_preset=maximum&level=campaign&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"campaign.id","operator":"IN", "value":${campaignes}}]`
    );
    facebookApi(
      "adsetsData",
      facebookAccountId,
      `/insights?fields=adset_name,adset_id,created_time,impressions,campaign_name,campaign_id,reach,spend,objective,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_continuous_2_sec_watched_actions,video_15_sec_watched_actions,video_30_sec_watched_actions,video_play_actions,video_avg_time_watched_actions,actions&action_breakdowns=["action_reaction"]&limit=100&date_preset=maximum&level=adset&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"campaign.id","operator":"IN", "value":${campaignes}}]`
    );
    facebookApi(
      "uniqueCampaignsData",
      facebookAccountId,
      `/insights?fields=impressions,campaign_name,campaign_id,reach,spend,objective,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_continuous_2_sec_watched_actions,video_15_sec_watched_actions,video_30_sec_watched_actions,video_play_actions,video_avg_time_watched_actions,actions&action_breakdowns=["action_reaction"]&limit=100&date_preset=maximum&level=account&filtering=[{"field":"campaign.name","operator":"CONTAIN", "value":"${idEntreprise}"},{"field":"campaign.id","operator":"IN", "value":${campaignes}}]`
    );
    creativeIdFunc(facebookAccountId);
  };

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  let result =
    allCreatives !== null ? (
      <div>
        <form id="main-form" onSubmit={handleSubmit}>
          <article
            // className="contact-form"
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              width: "50%",
              textAlign: "center",
              margin: "0px auto",
              borderRadius: "0px  20px",

              // marginTop: "20px",
            }}
          >
            {/* <Typography component="h3" variant="h3" className="form-group">
                rapport
              </Typography> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="form-group">
                <TextField
                  value={selectedClient}
                  name="selectedMarque"
                  select
                  variant="outlined"
                  label="Compte"
                  id="selectedMarque"
                  form="main-form"
                  margin="normal"
                  fullWidth
                  onChange={(e) => {
                    setSelectedClient(e.target.value);
                    setLoading(true);
                    setSelectedCampaigns([]);
                    // console.log(e.target);
                  }}
                  required
                >
                  {marques &&
                    marques.map((item, key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </div>
              <div className="form-group" style={{ width: "80%" }}>
                {facebookAccountId ? (
                  <Autocomplete
                    disabled={loading}
                    multiple
                    margin="normal"
                    debug
                    id="selectedCampaigns"
                    filterSelectedOptions
                    options={allCampaigns}
                    getOptionLabel={(option) =>
                      // option.campaign_name.includes("|")
                      //   ? option.campaign_name.split["|"][0]
                      option.campaign_name
                    }
                    value={selectedCampaigns}
                    onChange={(event, newValue) => {
                      setSelectedCampaigns(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Filtrer par campagne"
                        placeholder="Ajouter une campagne"
                        required={selectedCampaigns.length === 0 ? true : false}
                      />
                    )}
                  />
                ) : (
                  <CircularProgress />
                )}
              </div>
              <Button
                disabled={loading}
                style={{ margin: "0px 20px" }}
                type="submit"
                form="main-form"
                color="primary"
                variant="contained"
                size="large"
                // className="submit-btn"
              >
                {loading ? <CircularProgress /> : "Envoyer"}
              </Button>
            </div>
          </article>
        </form>
        {!loading ? (
          <>
            <div
              style={{
                width: "100%",
                backgroundColor: "#f2f2f2",
                padding: "10px",
              }}
            >
              <TextField
                value={selectedReport}
                name="selectedReport"
                select
                style={{ padding: "10px 0px 10px 10px" }}
                variant="outlined"
                label="Type de campagne"
                id="selectedReport"
                form="main-form"
                onChange={(e) => {
                  setSelectedReport(e.target.value);
                }}
                required
              >
                <MenuItem value="Diffusion">Diffusion</MenuItem>
                <MenuItem value="Engagement">Engagement</MenuItem>
                <MenuItem value="Video">Video</MenuItem>
              </TextField>
            </div>
            <Boards
              uniqueCampaignsData={uniqueCampaignsData}
              adsetsData={adsetsData}
              campaignsData={campaignsData}
              selectedReport={selectedReport}
              level={level}
              uniqueAds={uniqueAds}
              handleChange={handleChange}
              ads={ads}
              loading={loading}
            />
          </>
        ) : null}
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#222",
          // border: "3px solid green",
          // padding: "10px",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        height: "100vh",
        margin: "0px",
      }}
    >
      {result}
    </div>
  );
}

export default Rapport;

function getUniqueAds(ads) {
  let uniqueObjectStoryIdList = [
    ...new Set(ads.map((item) => item.responseCreative.object_story_id)),
  ].filter((el) => {
    return el !== undefined;
  });
  let sourceInstagramMediaIdList = [
    ...new Set(
      ads.map((item) => item.responseCreative.source_instagram_media_id)
    ),
  ].filter((el) => {
    return el !== undefined;
  });

  let unique = _.uniqBy(ads, kk);

  // console.log("ads", ads);

  // console.log("uniqBy", unique);

  function kk(ads) {
    let result;
    if (ads.responseCreative.source_instagram_media_id) {
      result = ads.responseCreative.source_instagram_media_id;
    } else if (ads.responseCreative.object_story_id) {
      result = ads.responseCreative.object_story_id;
    } else if (ads.responseCreative.object_story_spec) {
      result = ads.responseCreative.object_story_spec;
    }

    return result;
  }

  let uniqueObjectStory = unique.map((element) => {
    const result = ads.filter((el) => {
      if (
        el.responseCreative.object_story_id ===
        element.responseCreative.object_story_id
      ) {
        return el.responseCreative.object_story_id;
      }
    });

    return result;
  });

  let uniqueSourceInstagramMediaId = unique.map((element) => {
    const result = ads.filter((el) => {
      if (
        el.responseCreative.source_instagram_media_id ===
        element.responseCreative.source_instagram_media_id
      ) {
        return el.responseCreative.source_instagram_media_id;
      }
    });
    return result;
  });

  let uniqueDarkPost = unique.map((element) => {
    const result = ads.filter((el) => {
      if (
        el.responseCreative.object_story_spec !== undefined &&
        element.responseCreative.object_story_spec !== undefined
      ) {
        if (
          el.responseCreative.object_story_spec.video_data !== undefined &&
          element.responseCreative.object_story_spec.video_data !== undefined
        ) {
          if (
            el.responseCreative.object_story_spec.video_data.video_id ===
              element.responseCreative.object_story_spec.video_data.video_id &&
            el.responseCreative.object_story_spec.video_data.message ===
              element.responseCreative.object_story_spec.video_data.message
          ) {
            return el;
          }
        } else {
          if (
            el.responseCreative.object_story_spec.link_data !== undefined &&
            element.responseCreative.object_story_spec.link_data !== undefined
          ) {
            if (
              el.responseCreative.object_story_spec.link_data.link ===
                element.responseCreative.object_story_spec.link_data.link &&
              el.responseCreative.object_story_spec.link_data.message ===
                element.responseCreative.object_story_spec.link_data.message
            ) {
              return el;
            }
          }
        }
      }
    });
    return result;
  });

  uniqueSourceInstagramMediaId = _.uniq(uniqueSourceInstagramMediaId).filter(
    (el) => el.length !== 0
  );
  uniqueObjectStory = _.uniq(uniqueObjectStory).filter((el) => el.length !== 0);
  uniqueDarkPost = _.uniq(uniqueDarkPost).filter((el) => el.length !== 0);

  uniqueSourceInstagramMediaId.map((el) => {
    el[0].responseCreative.platform = "Instagram";
    el[0].responseCreative.type = "Post";
  });
  uniqueObjectStory.map((el) => {
    el[0].responseCreative.platform = "Facebook";
    el[0].responseCreative.type = "Post";
  });

  // console.log("uniqueSourceInstagramMediaId", uniqueSourceInstagramMediaId);
  // console.log("uniqueObjectStory", uniqueObjectStory);
  // console.log("uniqueDarkPost", uniqueDarkPost);

  let uniqueAds = uniqueSourceInstagramMediaId
    .concat(uniqueObjectStory)
    .concat(uniqueDarkPost);

  // console.log("uniqueAds", uniqueAds);

  uniqueAds = uniqueAds.filter((el) => {
    return el != null && el != "" && el != undefined;
  });

  let tab = [];
  let ad_id = [];
  let object_story_id = [];
  let thumbnail_url = [];
  let object_type = [];
  let effective_object_story_id = [];
  let body = [];
  let instagram_permalink_url = [];
  let object_story_spec = [];
  let object_id = [];
  let object_url = [];
  let link_url = [];
  let source_instagram_media_id = [];
  let status = [];

  uniqueAds.map((element, index) => {
    ad_id[index] = uniqueAds[index].map((el, i) => {
      return el.ad_id.toString();
    });
    object_story_id[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.object_story_id;
    });
    instagram_permalink_url[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.instagram_permalink_url;
    });
    effective_object_story_id[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.effective_object_story_id;
    });
    thumbnail_url[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.thumbnail_url;
    });
    object_type[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.object_type;
    });
    body[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.body;
    });
    object_story_spec[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.object_story_spec;
    });
    object_id[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.object_id;
    });
    object_url[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.object_url;
    });
    link_url[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.link_url;
    });
    source_instagram_media_id[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.source_instagram_media_id;
    });
    status[index] = uniqueAds[index].map((el, i) => {
      return el.responseCreative.status;
    });

    tab[index] = {
      ad_id: [...new Set(ad_id[index])],
      object_story_id: [...new Set(object_story_id[index])],
      effective_object_story_id: [...new Set(effective_object_story_id[index])],
      thumbnail_url: [...new Set(thumbnail_url[index])],
      object_type: [...new Set(object_type[index])],
      body: [...new Set(body[index])],
      instagram_permalink_url: [...new Set(instagram_permalink_url[index])],
      object_story_spec: [...new Set(object_story_spec[index])],
      object_id: [...new Set(object_id[index])],
      object_url: [...new Set(object_url[index])],
      link_url: [...new Set(link_url[index])],
      source_instagram_media_id: [...new Set(source_instagram_media_id[index])],
      status: [...new Set(status[index])],
    };
  });

  return tab;
}
